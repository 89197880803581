@use "helpers/variables" as v;
@use "core/typography" as t;
@use "helpers/classes" as c;
@use "helpers/mixins" as m;

#colophon{
    position: fixed;
    padding-top: clamp(4rem,7vw,6rem);
    padding-bottom: clamp(2rem,7vw,3rem);
    bottom: 0px;
    z-index: 0;
    width: 100%;

    a{
        text-decoration: none;
        font-weight: 600;
        border-radius: 20px;

        &:focus-visible{
            outline: 2px solid v.$ps-white;
            outline-offset: 10px;
        }
    }

    .base-layout{
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        gap: clamp(1.5rem,7vw,3rem);

        .row{
            justify-content: space-between;
            flex-wrap: wrap;
            gap: 1.5rem;

            .copyright{
                display: flex;
                gap: 1rem;

                .seperator{
                    width: 1px;
                    height: 100%;
                    background-color: rgba(v.$ps-white,0.5);
                }
            }

            .credits a{
                display: flex;
                gap: 0.5rem;

                span{
                    white-space: nowrap;
                    font-weight: 600;
                }
            }
        }
    }

    .section__background{
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;

        img{
            position: absolute;
            inset: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

.nav-open{
    #colophon{
        display: none;
    }
}