@use 'helpers/variables' as v;
@use 'helpers/functions' as f;

/* ------------------------------------------------
    Typography
-------------------------------------------------*/
/* Typography Base */
@mixin heading {
    font-family: v.$heading-font;
    font-style: normal;
    color: rgba(v.$ps-dark,1);
    margin: 0;
    font-weight: v.$weight-bold;
}

@mixin body {
    font-family: v.$body-font;
    line-height: 150%;
    color:rgba(v.$ps-dark,1);
    font-weight: v.$weight-regular;
}

/* ------------------------------------------------
    Layout
-------------------------------------------------*/
@mixin content-width {
    width: 100%;
    max-width: calc(v.$desktop-breakpoint * 1px);
}

@mixin tablet-width {
    width: 100%;
    max-width: 980px;
}

@mixin base-layout {
    margin-left: auto;
    margin-right: auto;
    padding: 0 1rem;
}