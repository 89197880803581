@use "../../helpers/variables" as v;
@use "../../core/typography" as t;
@use "../../helpers/classes" as c;
@use "../../helpers/mixins" as m;

.video-modal{
    display: none;
    position: fixed;
    inset: 0;
    justify-content: center;
    align-items: center;
    padding: 3rem;
    background: rgba(v.$ps-dark, 0.7);
    backdrop-filter: blur(4px);

    &.is-open {
        display: flex;
        z-index: 3;
    }

    [role="dialog"] {
        width: 100%;
        max-width: 1208px;
        position: relative;
        
        


        video{
            max-width: 100%;
            border-radius: var(--border-radius);
            overflow: hidden;
            box-shadow: 0px 4px 70.7px 0px rgba(0, 0, 0, 0.35);
            width: 100%;
            max-height: calc(100vh - 100px);
            background-color: black;

            &:focus-visible{
                outline: 2px solid v.$ps-white;
                outline-offset: 4px;
            }
        }

        .x-close{
            background-color:#04210D;
            width: 60px;
            height: 60px;
            position: absolute;
            right: -25px;
            top: -25px;
            border-radius: 100vw;
            display: flex;
            align-items: center;
            justify-content: center;
            transform: rotate(45deg);

            &::before,
            &::after{
                content: '';
                position: absolute;
                width: 25px;
                height: 2px;
                border-radius: 3px;
                background-color: v.$ps-white;  
                transform-origin: center;
            }

            &::before{
                transform: rotate(-90deg);
            }

            &:focus-visible{
                outline: 2px solid v.$ps-white;
                outline-offset: -8px;
            }
        }
    }

    &.is-animating.is-opening [role=dialog]{
        animation: scale-in .4s v.$timing-function;
    }

    &.is-animating.is-closing [role=dialog]{
        animation: scale-out .4s v.$timing-function;
    }

    &.is-animating.is-opening{
        animation: fade-in .4s v.$timing-function;
    }

    &.is-animating.is-closing{
        animation: fade-out .4s v.$timing-function;
    }
}

@media screen and (max-width:767px){
    .video-modal{
        padding: 1rem;

        .x-close{
            right: auto !important;
            left: 50% !important;
            transform: rotate(45deg) translateX(-50%) !important;
            top: -60px !important;

        }
    }
}

@keyframes scale-in {
    from {
        opacity: 0;
        transform: scale(0.9);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes scale-out {
    from {
        opacity: 1;
        transform: scale(1);
    }
    to {
        opacity: 0;
        transform: scale(0.9);
    }
}

@keyframes fade-in {
    from {
        backdrop-filter: blur(0px);
        background: rgba(v.$ps-dark, 0);
    }
    to {
        backdrop-filter: blur(4px);
        background: rgba(v.$ps-dark, 0.7);
    }
}

@keyframes fade-out {
    from {
        backdrop-filter: blur(4px);
        background: rgba(v.$ps-dark, 0.7);
    }
    to {
        backdrop-filter: blur(0px);
        background: rgba(v.$ps-dark, 0);
    }
}