@use 'helpers/variables' as v;
@use 'helpers/mixins' as m;

/* ------------------------------------------------
    Color Classes
-------------------------------------------------*/
@each $label, $colour in v.$colours {
    .#{$label}-bg {
      background-color: $colour;
    }

    .#{$label},
    .#{$label} *{
        color: $colour;
    }

    :root{
        --#{$label}: #{$colour};
    }
}

 
/* ------------------------------------------------
    Weights
-------------------------------------------------*/
@each $label, $weight in v.$font-weights {
    .#{$label},
    .weight-#{$weight} {
      font-weight: $weight;
    }
}



/* ------------------------------------------------
    Utility Classes
-------------------------------------------------*/
.content-width {
    @include m.content-width;
}

.tablet-width {
    @include m.tablet-width;
}

.base-layout{
    @include m.base-layout;
}

.has-no-margin{
    margin: 0 !important;
}

.has-no-gap {
    gap: 0 !important;
}

.is-margin-centered {
    margin-left: auto;
    margin-right: auto;
}

.is-text-centered {
    text-align:center;
}

.is-visually-hidden {
    border: 0;
    clip: rect(1px,1px,1px,1px);
    -webkit-clip-path: inset(50%);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    word-wrap: normal !important;
}

.is-overflow-hidden {
    overflow: hidden;
}


/* ------------------------------------------------
    Grid Column Classes
-------------------------------------------------*/
.grid-cols {
    display: grid;

    .col{
        display: flex;
        flex-direction:column;
        min-height: 0;
        min-width: 0;
    }

    .row{
        display: flex;
        flex-direction:row;
        min-height: 0;
        min-width: 0;
    }
}

.grid-cols--1 {
    grid-template-columns: 1fr;
}

.grid-cols--5-7 {
    gap: 1em;
    grid-template-columns: 5fr 7fr;
}

.grid-cols--2-1 {
    gap: 1em;
    grid-template-columns: 2fr 1fr;
}

@media only screen and (max-width: 1000px) {
    .grid-cols--5-7, 
    .grid-cols--2-1 {
        grid-template-columns: 1fr;
    }
}
