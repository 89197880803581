@use "../../helpers/variables" as v;
@use "../../core/typography" as t;
@use "../../helpers/classes" as c;
@use "../../helpers/mixins" as m;

.gallery-swiper{
    padding-left: calc(((100vw - 1200px) / 2));

    &:focus-visible{
        outline: 2px solid v.$ps-dark;
        outline-offset: -5px;
        border-radius: var(--border-radius);
    }

    .swiper-wrapper{
        align-items: center;

        *{
            user-select: none;
        }

        .swiper-slide{
            width: auto !important;
            padding-right: clamp(1rem,5vw,3.25rem);
            
            figure{
                img{
                    max-width: 450px;
                    max-height:450px;
                    width: 100%;
                    height: auto;

                    &.small{
                        max-height: 350px;
                    }
                }
            }

            &:last-child{
                padding-right: calc(((100vw - 1240px) / 2));
            }
        }
    }
}

@media screen and (max-width:1260px){
    .gallery-swiper{
        .swiper-wrapper{
            .swiper-slide{
                &:last-child{
                    padding-right: 1.5rem !important;
                }
                &:first-child{
                    padding-left: 1.5rem !important;
                }
            }
        }
    }
}

@media screen and (max-width:1000px){
    .gallery-swiper{
        .swiper-wrapper{
            .swiper-slide{
                figure{
                    img{
                        max-width: 350px;
                        max-height:350px;

                        &.small{
                            max-height: 250px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width:400px){
    .gallery-swiper{
        .swiper-wrapper{
            .swiper-slide{
                figure{
                    img{
                        max-width: 70vw;
                        max-height:250px;

                        &.small{
                            max-height: 200px;
                        }
                    }
                }
            }
        }
    }
}