@use 'helpers/variables' as v;
@use 'helpers/mixins' as m;
@use 'helpers/classes' as c;
@use 'helpers/functions' as f;
@use "sass:map";


/* ------------------------------------------------
    Heading Typography
-------------------------------------------------*/
/* Base Heading Stying */
h1, h2, h3, h4, h5, h6{
    @include m.heading;
}

h2{
    letter-spacing: -0.05rem;
    line-height: 110%;
    text-transform: uppercase;
}

/* Generate/apply heading sizes, classes and CSS properties */
@each $label, $size in v.$heading-sizes {
    $levels: ("h1", "h2", "h3", "h4", "h5", "h6");

    $slug : map.get($size, "slug");
    $mobile : map.get($size, "mobile");
    $desktop : map.get($size, "desktop");

    #{$label}{
        font-size: #{f.scssClamp($mobile , $desktop)};
    }
    .#{$slug}-heading,
    .#{$slug}-heading > #{$label}{
        font-size: #{f.scssClamp($mobile , $desktop)};
    }

    :root{
        --#{$slug}-heading: #{f.scssClamp($mobile , $desktop)};
    }

    @each $level in $levels {
        @if $label != $level {
            #{$level}.#{$slug}-heading,
            .#{$slug}-heading > #{$level}{
                font-size: #{f.scssClamp($mobile , $desktop)};
            }
        }
    }
}


/* ------------------------------------------------
    Body Typography
-------------------------------------------------*/
/* Base body styling */
p,
button,
ul,
ol,
li,
label,
input,
select,
small,
legend,
textarea,
details,
summary,
blockquote,
span {
    @include m.body;
}

/* Generate/apply body sizes/classes and CSS properties */
@each $label, $size in v.$body-sizes {

    $mobile : map.get($size, "mobile");
    $desktop : map.get($size, "desktop");

    
    .#{$label}-body,
    .#{$label}-body *{
        font-size: #{f.scssClamp($mobile , $desktop)};
    }

    :root{
        --#{$label}-body: #{f.scssClamp($mobile , $desktop)};
    }
}