@use "helpers/variables" as v;
@use "core/typography" as t;
@use "helpers/classes" as c;
@use "helpers/mixins" as m;

.site-nav{
    .site-menu{
        display: flex;
        flex-direction: row;
        margin: 0;
        padding:0;
        list-style: none;
        gap: 0.5rem;

        .menu-item{
            .menu-link{
                text-decoration: none;
                text-transform: uppercase;
                padding: 0.5rem 1rem;
                border-radius: 100vw;

                &:focus-visible{
                    outline-color: v.$ps-white;

                    span{
                        color: rgba(v.$ps-white,1);
                    }
                }

                span{
                    color: rgba(v.$ps-white,0.8);
                    font-weight: 300;
                }
            }

            &:last-child{
                span{
                    font-weight: 600;
                    color: rgba(v.$ps-white,1);
                }
            }
        }
    }

    .mobile-menu-toggle{
        display: none;
    }
}


@media screen and (max-width:1000px){
    .mobile-menu-toggle{
        display: flex !important;
        width: 45px;
        height: 45px;
        border-radius: 100vw;
        display: flex;
        align-items: center;
        justify-content: center;

        &[f11y-layer-open]{
            border: 1px solid v.$ps-white;
            flex-direction: column;
            gap: 5px;

            &::before,
            &::after{
                content: '';
                width: 25px;
                height: 1px;
                border-radius: 3px;
                background-color: v.$ps-white;  
                transform-origin: center;
            }
        }

        &[f11y-layer-close]{
            background-color: v.$ps-white;
            position:absolute;
            top: -60px;
            left: 50%;
            transform: translate(-50%, 0px) rotate(45deg);
            z-index: 1;

            &::before,
            &::after{
                content: '';
                position: absolute;
                width: 25px;
                height: 2px;
                border-radius: 3px;
                background-color: v.$ps-dark;  
                transform-origin: center;
            }

            &::before{
                transform: rotate(-90deg);
            }
        }

        &:focus-visible{
            outline: 2px solid v.$ps-white;
            outline-offset: 4px;
        }
    }


    .nav-modal{
        display:none;
        position: fixed;
        inset: 0;
        justify-content: center;
        align-items: flex-end;
        background: rgba(v.$ps-dark, 0.1);
        backdrop-filter: blur(4px);

        [role="dialog"] {
            position: relative;
            max-width: calc(100% - 3rem);
            max-height: calc(90vh - 60px);
            background-color: v.$ps-white;
            border-radius: var(--border-radius) var(--border-radius) 0px 0px;
            height: 100%;
            width: 100%;

            &:after{
                content: '';
                position: absolute;
                bottom: -100px;
                width: 100%;
                height: 100px;
                background-color: v.$ps-white;
            }
        }

        [role="dialog"] {
            .site-menu{
                padding: 2rem 2rem;
                flex-direction: column;
                background-color: v.$ps-white;
                border-radius: var(--border-radius) var(--border-radius) 0px 0px;
                position: relative;
                z-index: 2;
                gap: 0;

                .menu-item{
                    padding: 0.3rem 0rem;
                    &:not(:last-child){
                        border-bottom: 1px solid rgba(v.$ps-dark,0.3);
                    }
                    .menu-link{
                        padding: 1rem 0.3rem;
                        display: flex;
                        border-radius: 0px;

                        &:focus-visible{
                            outline: 2px solid v.$ps-dark;
                        }

                        span{
                            color: v.$ps-dark;
                            font-family: 'Red Hat Display';
                            font-weight: 300;
                            text-transform: capitalize;
                            font-size: var(--x-large-heading);
                            line-height: 130%;
                        }
                    }
                }
            }
        }

        &.is-open{
            display: flex;
            z-index:10;
        }

        &.is-animating.is-opening [role=dialog]{
            animation: move-in .5s cubic-bezier(0.505, 0.215, 0.330, 1.190);
        }
    
        &.is-animating.is-closing [role=dialog]{
            animation: move-out .5s cubic-bezier(0.505, 0.215, 0.330, 1.190);
        }
    
        &.is-animating.is-opening{
            animation: nav-fade-in .5s v.$timing-function;
        }
    
        &.is-animating.is-closing{
            animation: nav-fade-out .5s v.$timing-function;
        }

        &.is-animating.is-opening .mobile-menu-toggle[f11y-layer-close]{
            animation: close-move-in .5s cubic-bezier(0.505, 0.215, 0.330, 1.190);
        }
    
        &.is-animating.is-closing .mobile-menu-toggle[f11y-layer-close]{
            animation: close-move-out .5s cubic-bezier(0.505, 0.215, 0.330, 1.190);
        }
    }
}

#content{
    transition: transform 0.4s ease;
}

.nav-open{
    #content{
        transform-origin: top center;
        transform: scale(0.87) translateY(20vh);
        border-radius: var(--border-radius) var(--border-radius) 0px 0px;
        overflow: hidden;
    }
}

@keyframes move-in {
    from {
        transform: translateY(100%);
    }
    to {
        transform: translateY(0%);
    }
}

@keyframes move-out {
    from {
        transform: translateY(0%);
    }
    to {
        transform: translateY(100%);
    }
}

@keyframes close-move-in {
    from {
        transform: translate(-50%, 100px) rotate(45deg);
    }
    to {
        transform: translate(-50%, 0px) rotate(45deg);
    }
}

@keyframes close-move-out {
    from {
        transform: translate(-50%, 0px) rotate(45deg);
    }
    to {
        transform: translate(-50%, 100px) rotate(45deg);
    }
}

@keyframes nav-fade-in {
    from {
        backdrop-filter: blur(0px);
        background: rgba(v.$ps-dark, 0);
    }
    to {
        backdrop-filter: blur(4px);
        background: rgba(v.$ps-dark, 0.1);
    }
}

@keyframes nav-fade-out {
    from {
        backdrop-filter: blur(4px);
        background: rgba(v.$ps-dark, 0.1);
    }
    to {
        backdrop-filter: blur(0px);
        background: rgba(v.$ps-dark, 0);
    }
}