@use 'helpers/variables' as v;
@use 'typography' as t;
@use 'helpers/mixins' as m;
@use 'helpers/classes' as c;

html {
    -webkit-text-size-adjust: 100%;
    font-family: v.$body-font;
    color: rgba(v.$ps-dark,1);
    scrollbar-gutter: stable;
    font-size:1em;
}

html.lenis {
    height: auto;
}

.lenis.lenis-smooth {
    scroll-behavior: auto !important;
}

.lenis.lenis-smooth [data-lenis-prevent] {
    overscroll-behavior: contain;
}

.lenis.lenis-stopped {
    overflow: hidden;
}

.lenis.lenis-scrolling iframe {
    pointer-events: none;
}

*, 
*::before,
*::after{
    box-sizing: border-box;
    font-size:1em;
}

*:focus-visible{
    outline: 2px solid v.$ps-dark;
}

@media (prefers-reduced-motion: reduce) {
    html {
        scroll-behavior: auto;
    }
}

@media (prefers-reduced-motion) {
    html {
        scroll-behavior: auto;
    }
    *{
        transition: transform 0s linear, opacity 0s linear !important;
        animation-duration: 0s;
        animation-timing-function: linear;
    }
}

body {
    margin: 0;
}

main {
    display: block;
}

article{
    position: relative;
}

hr {
    box-sizing: content-box;
    height: 1px;
    overflow: visible;
    width: 100%;
    background-color: rgba(v.$ps-dark,1);
    border: none;
}

pre {
    font-family: monospace, monospace;
    font-size: 1em;
}

a {
    background-color: transparent;
    cursor:pointer;
}

abbr[title] {
    border-bottom: none;
    text-decoration: underline;
    text-decoration: underline dotted;
}

p{
    margin: 0.1em 0;
}

strong{
    font-weight: v.$strong-weight;
}

ul,ol{
    margin-block-start: 0;
    margin-block-end: 0.67rem;
    padding-inline-start: 1.35rem;
}

ul ol,
ul ul,
ol ul,
ol ol{
    margin-bottom: 0;
}

.inner-content ul li:not(:last-of-type),
.inner-content ol li:not(:last-of-type){
    margin-bottom: 0.5rem;
}

code,
kbd,
samp {
    font-family: monospace, monospace;
    font-size: 1em;
}

small {
    font-size: 80%;
}

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

sub {
    bottom: -0.25em;
}

sup {
    top: -0.3em;
}

em{
    font-style: italic;
}

img {
    display: block;
    width: 100%;
    border-style: none;
    max-width:100%;
    height: auto;
}

button,
input,
optgroup,
select,
textarea {
    margin: 0;
    padding-inline: 0px;
    padding-block: 0px;
}

::-moz-placeholder,
::placeholder,
::-webkit-input-placeholder{
    color: rgba(v.$ps-dark,0.75);
}

input:focus::placeholder,
input:focus::-moz-placeholder,
input:focus::-webkit-input-placeholder{
    opacity:0.2;
}

button,
select {
    text-transform: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
    appearance: button;
    -webkit-appearance: button;
    cursor: pointer;
    background: transparent;
    border:none;
    text-align: left;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0;
}

legend {
    box-sizing: border-box; 
    color: inherit;
    display: table;
    max-width: 100%;
    padding: 0;
    white-space: normal;
}

progress {
    vertical-align: baseline;
}

textarea {
    overflow: auto;
}

[type="checkbox"],
[type="radio"] {
    box-sizing: border-box;
    padding: 0; 
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
    height: auto;
}

[type="search"] {
    appearance: textfield;
    -webkit-appearance: textfield;
    outline-offset: -2px; 
}

[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

::-webkit-file-upload-button {
    -webkit-appearance: button; 
    font: inherit;
}

details {
    display: block;
    list-style: none;
}

summary {
    display: list-item;
    list-style: none;
}

template {
    display: none;
}

.js-enabled [hidden] {
    display: none;
}

figure{
    margin: 0;
}

figure > video + figcaption,
figure > img + figcaption{
    @extend .small-body;
}

figure > video + figcaption{
    margin-top:5px;
}

fieldset{
    background: transparent;
    border: none;
    padding: 0;
    margin-bottom: 1.5rem;
}

blockquote{
    margin-inline: 0;
}

blockquote p:last-child{
    margin-bottom: 0;
}