@use "../../helpers/variables" as v;
@use "../../core/typography" as t;
@use "../../helpers/classes" as c;
@use "../../helpers/mixins" as m;

.section--intro{
    position: relative;
    .col{
        gap: 2rem;
        > p{
            font-family: 'Red Hat Display';
            font-size: var(--huge-body);
            font-weight: v.$weight-light;
        }

        .video-autoplay{
            max-width: 900px;
            border-radius: var(--border-radius);
            overflow: hidden;
            position: relative;

            video{
                width: 100%;
                display: block;
            }

            button{
                position: absolute;
                left: 50%;
                bottom: 0%;
                padding: 1.25em 1.25em !important;
                transform: translate(-50%, -50%) scale(0.85);
                opacity: 0.65;
                transition: transform 0.3s v.$timing-function, opacity 0.3s v.$timing-function;
    
                &:focus-visible{
                    outline: 2px solid v.$ps-white;
                    outline-offset: -8px;
                }

                &.is-muted{
                    .volume{
                        display: none;
                    }

                }

                &:not(.is-muted){
                    .muted{
                        display: none;
                    }
                }
            }

            &:focus-within,
            &:hover{
                button{
                    transform: translate(-50%, -50%) scale(1);
                    opacity: 1;
                }
            }
        }

        .intro__poster{
            position: relative;
            border-radius: var(--border-radius);
            overflow: hidden;
            max-width: 900px;
            
            button{
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
    
                &:focus-visible{
                    outline: 2px solid v.$ps-white;
                    outline-offset: -8px;
                }
            }
        }

        .cta{
            align-self: flex-start;
            justify-self: flex-start;
            display: inline-flex;
            flex-direction: column;
            gap: 1.35rem;
            justify-self:flex-end;
            align-items: flex-start;
            margin-top: 2rem;
            border-top: 1px solid rgba(v.$ps-dark,0.35);
            padding-top:0.75rem;
            padding-bottom: 3rem;

            *{
                text-transform: uppercase;
            }
        }
    }

    .flourish{
        position: absolute;
        right: -30%;
        bottom: 5%;
        z-index: -1;
    }
}

@media screen and (max-width:767px){
    .section--intro{
        .flourish{
            max-width: 75vw;
            right: -10%;
        }
    }
}