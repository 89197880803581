@use 'helpers/variables' as v;
@use 'typography' as t;
@use 'helpers/mixins' as m;
@use 'helpers/classes' as c;
@use 'helpers/functions' as f;

#page{
    background-color: #0e1a12;

    #content{
        background-color: v.$ps-white;
        padding-bottom: clamp(4rem,10vw,7rem);
        position: relative;
        z-index: 1;
        margin-bottom: var(--footer-height);

        .hentry{
            overflow: hidden;
        
            > .inner{
                &:before{
                    content: '';
                    width:100%;
                    position: absolute;
                    height: 31px;
                    background-color: v.$ps-white;
                    left: 0;
                    border-radius: var(--border-radius) var(--border-radius) 0px 0px;
                    top: -30px;
                }

                padding-top: clamp(4rem,10vw,8rem);
                position: relative;
            }
        }
    
        &:after{
            content: '';
            width:100%;
            position: absolute;
            height: 31px;
            background-color: v.$ps-white;
            left: 0;
            border-radius: 0px 0px var(--border-radius) var(--border-radius);
            bottom: -30px;
        }
    }
}

.site-logo{
    img{
        width:100%;
        max-width: 160px;
        height: auto;
    }
}


