@use "../../helpers/variables" as v;
@use "../../core/typography" as t;
@use "../../helpers/classes" as c;
@use "../../helpers/mixins" as m;

#section--hero{
    position: relative;
    padding-top: var(--header-height);

    .inner{
        position: relative;
        z-index: 2;

        .grid-cols--2-1{
            padding-top: clamp(2.5rem,10vw,5rem);
            

            h1{
                line-height: 100%;
                text-transform: uppercase;
                span{        
                    line-height: 100%;
                    display: block;
        
                    &.heading--outlined{
                        color: transparent;
                        font-weight: v.$weight-bold;
                        -webkit-text-stroke: 1px v.$ps-white;
                        -webkit-text-stroke-width: 1;
                        -webkit-text-stroke-color: v.$ps-white;
                        letter-spacing: -0.15rem;
                    }
    
                    &.heading--serif{
                        font-family: 'Kalnia';
                        color: v.$ps-white;
                        letter-spacing: -0.375rem;
                        font-weight: 400;
                        padding-left: 0.7em;
                    }
                }
            }
    
            .cta{
                display: flex;
                flex-direction: column;
                gap: 2.5rem;
                justify-self:flex-end;
                align-items: flex-end;
                margin-top: auto;
                border-top: 1px solid rgba(v.$ps-white,0.35);
                padding-top:1rem;
                padding-bottom: 1rem;
    
                *{
                    text-align: right;
                    text-transform: uppercase;
                    color: v.$ps-white;
                }
            }
        }
    }

    .inner--full{
        padding-bottom: 2rem;
    }

    .gallery-swiper{
        padding-top: clamp(3.5rem,12vw,7rem);
        padding-bottom: clamp(4rem,10vw,8rem);

        &:focus-visible{
            outline: 2px solid v.$ps-white;
            outline-offset: -5px;
            border-radius: var(--border-radius);
        }


        .swiper-wrapper{
            .swiper-slide{
                figure{
                    img{
                        box-shadow: 0px 4px 70.7px 0px rgba(0, 0, 0, 0.35);
                    }
                }
            }
        }
    }

    .section__background{
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;

        img{
            position: absolute;
            inset: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

@media screen and (max-width:1000px){
    #section--hero{
        .grid-cols .col{
            &:last-child{
                margin-left: calc(var(--huge-heading) * 0.7);

                .cta{
                    align-items: flex-start;

                    *{
                        text-align: left;
                    }
                }
            }
            
        }
    }
}

@media screen and (max-width:400px){
    #section--hero{
        .heading--serif{
            padding-left: 0 !important;
        }
        .grid-cols .col{
            &:last-child{
                margin-left: 0;
            }

            .btn{
                width: 100%;
            }
        }
    }
}