@use "../../helpers/variables" as v;
@use "../../core/typography" as t;
@use "../../helpers/classes" as c;
@use "../../helpers/mixins" as m;

.btn{
    display: inline-flex !important;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 1.25em 4em;
    border-radius: 100vw;
    background-color:#04210D;
    color: v.$ps-white;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: 600;
    position: relative;
    overflow: hidden;
    white-space: nowrap;

    &:not(.outlined):after{
        content: '';
        position: absolute;
        width: 400px;
        height: 275px;
        background: radial-gradient(circle, rgba(255,255,255,0) 0%, rgba(255,255,255,0.748319396117822) 27%, rgba(255,255,255,0.8855742980786064) 38%, rgba(255,255,255,0.921988863904937) 50%, rgba(255,255,255,0.8323530095632002) 63%, rgba(255,255,255,0.6026611328125) 73%, rgba(255,255,255,0) 100%);
        right:0%;
        bottom: -100%;
        opacity: 0.1;
        transition: transform 0.3s v.$timing-function;
    }

    &:focus-visible,
    &:hover{
        &:after{
            transform: scale(2);
        }
    }

    &:focus-visible{
        outline: 2px solid v.$ps-dark;
        outline-offset: 3px;
    }

    &.outlined{
        border: 1px solid v.$ps-white;
        background-color: transparent;

        &:focus-visible{
            outline-width: 2px;
            outline-color: v.$ps-white;
        }
    }
}

@media screen and (max-width:767px){
    .btn{
        font-size: var(--small-body) !important;
    }
}