@use "../../helpers/variables" as v;
@use "../../core/typography" as t;
@use "../../helpers/classes" as c;
@use "../../helpers/mixins" as m;

.section--testimonial{
    .inner{
        position: relative;
        border-radius: var(--border-radius);
        overflow: hidden;

        .col{
            position: relative;
            z-index: 2;
            max-width: 767px;
            padding: clamp(1.35rem,7vw,4rem);
            padding-bottom: 250px;
    
            > img{
                max-width: 120px;
                height: auto;
            }
    
            blockquote{
                padding-top:0.5rem;
                p{
                    font-family: 'Red Hat Display';
                    font-weight: 300;
                    font-style: italic;
                    font-size: var(--x-large-body);
                    color: v.$ps-white;
                }
    
                footer{
                    display: flex;
                    flex-direction: column;
                    text-transform: uppercase;
                    color: v.$ps-white;
                    padding-top:1.5rem;
    
                    cite{
                        display: block;
                        font-style: normal;
                    }
                }
            }

            .flourish{
                position: absolute;
                margin-top: 2rem;
                margin-right:2rem;
                top: calc(100% - 200px);

                img{
                    opacity: 0;
                    transform: translateY(20%);
                    transition: opacity 0.6s cubic-bezier(.26,.28,.41,.7) 0.1s, transform 0.7s cubic-bezier(.26,.28,.41,.7);
                }


                &.has-transformed{
                    img{
                        opacity: 1;
                        transform: translateY(0%);
                    }
                }
            }
        }

        > img{
            position: absolute;
            height:100%;
            width: 100%;
            inset: 0;
            z-index: 1;
            object-fit:cover;
        }
    }
}

@media screen and (max-width:550px){
    .section--testimonial{
        .inner{
            .col{
                > img{
                    max-width: 80px;
                    margin-top: 1rem;
                }
            }
        }
        footer{
            font-size: var(--small-body);
        }
    }
}