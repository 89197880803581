@use "../../helpers/variables" as v;
@use "../../core/typography" as t;
@use "../../helpers/classes" as c;
@use "../../helpers/mixins" as m;

.section--service{
    position: relative;
    .col{
        &:first-child{
            padding-right: calc(1200px / 12);
            gap: 3rem;
        }

        figure{
            blockquote{
                margin: 0;
                border-left: 10px solid rgba(v.$ps-dark,0.08);
                padding-left: 2rem;

                p{
                    font-family: v.$pre-font;
                    font-size: var(--huge-body);
                    line-height: 150%;
                    letter-spacing: -0.02875rem;
                    font-weight:300;
                    margin: 0;
                }
            }
            figcaption{
                text-transform: uppercase;
                padding-top: 1rem;
                text-align:right;
                padding-right:30px;
            }
        }

        .cta{
            display: flex;
            flex-direction: column;
            gap: 1.35rem;
            justify-self:flex-end;
            align-items: flex-start;
            margin-top: auto;
            border-top: 1px solid rgba(v.$ps-dark,0.35);
            padding-top:0.75rem;

            *{
                text-transform: uppercase;
            }
        }
    }

    .service__poster{
        position: relative;
        border-radius: var(--border-radius);
        overflow: hidden;
        margin-top: clamp(2rem,7vw,4rem);

        figcaption{
            position: absolute;
            top: 2rem;
            left: 2rem;
            text-transform: uppercase;
            color: v.$ps-white;
        }
        
        button{
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);

            &:focus-visible{
                outline: 2px solid v.$ps-white;
                outline-offset: -8px;
            }
        }
    }

    &.section--burns,
    &.section--weddings{
        .flourish{
            max-width: 275px;
            height: auto;
            position: absolute;
            right: -3%;
            top: -8%;
            z-index: -1;
        }
    }

    &.section--funerals{
        .flourish{
            max-width: 275px;
            height: auto;
            position: absolute;
            right: -5%;
            top: 8%;
            z-index: -1;
        }
    }
}


@media screen and (max-width:1000px){
    .section--service{

        .grid-cols--5-7{
            gap: 2.5rem;
        }
        .col{
            &:first-child{
                padding-right: 0 !important;
                gap: 1.5rem !important;
            }

            figure{
                figcaption{
                    text-align: left !important;
                    padding-left: calc(10px + 2rem);
                    padding-right: calc(10px + 2rem);
                    padding-top:1.5rem;
                    font-size: var(--small-body);
                }
            }
        }

        &.section--burns,
        &.section--weddings{
            .flourish{
                top: -2% !important;
            }
        }

        &.section--funerals{
            .flourish{
                top: 6% !important;
            }
        }
    }
}

@media screen and (max-width:550px){
    .section--service{
        .cta{
            .btn {
                width: 100%;
            }
        }

        .service__poster{
            figcaption{
                left: 1.15rem;
                top: 1rem;
                font-size: var(--small-body);
            }

            .btn{
                top: auto;
                bottom: 1rem;
                transform: translate(-50%,0%);
            }
        }
    }
}