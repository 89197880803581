@use "../../helpers/variables" as v;
@use "../../core/typography" as t;
@use "../../helpers/classes" as c;
@use "../../helpers/mixins" as m;

.section--service--mini{
    .col{
        gap: 1rem !important;
        padding: 0 !important;
        h2{
            padding:0 !important;
        }

        p{
            font-family: 'Red Hat Display';
            font-size: var(--huge-body);
            font-weight: v.$weight-light;
            font-style: italic;
            line-height: 140%;
            letter-spacing: -0.03rem;
        }
    }

    .service__poster{
        margin-top: clamp(1.5rem,6vw,3rem);
    }
}