@use "../../helpers/variables" as v;
@use "../../core/typography" as t;
@use "../../helpers/classes" as c;
@use "../../helpers/mixins" as m;

.section--contact{
    position: relative;
    .col{
        &:first-child{
            max-width: 403px;
        }
        
        h2{
            padding-bottom:3rem;
        }

        .cta{
            display: flex;
            flex-direction: column;
            gap: 1.35rem;
            justify-self:flex-end;
            align-items: flex-start;
            margin-top: auto;
            border-top: 1px solid rgba(v.$ps-dark,0.35);
            padding-top:0.75rem;

            p:first-of-type{
                text-transform: uppercase;
            }

            a{
                font-weight: 600;
                color: v.$ps-dark;
                text-decoration: none;
                border-bottom: 2px solid v.$ps-dark;

                &:focus-visible{
                    border: none;
                    outline: 2px solid v.$ps-dark;
                    border-radius: 50px;
                    outline-offset: 4px;
                }
            }
        }


        #mc_embed_shell{
            #mc_embed_signup{
                form{
                    #mc_embed_signup_scroll{
                        *{
                            font-family: v.$body-font !important;
                        }

                        display: grid;
                        grid-template-columns: 1fr 1fr 1fr 1fr;
                        grid-template-rows: repeat(3,minmax(55px,auto));
                        grid-gap: 1.5rem;

                        #mce-responses,
                        .indicates-required{
                            width: 100%;
                            grid-column: 1 / span 4;
                        }

                        #mce-responses{
                            
                        }

                        .indicates-required{
                            display: flex;
                            align-items: flex-end;
                            justify-content: flex-end;
                            font-size: 14px;
                            font-weight: 600;
                            padding-right: 0.75rem;
                        }

                        .mc-field-group{
                            border: 1px solid rgba(v.$ps-dark,1);
                            border-radius: 100vw;
                            padding: 1rem 1.75rem 0.75rem;
                            position: relative;
                            flex-grow: 1;
                            width:100%;

                            &.details{
                                grid-column: 1 / span 4;
                            }
                            
                            &:focus-within{
                                outline: 2px solid rgba(v.$ps-dark,1);
                                outline-offset: 2px !important;
                            }

                            label{
                                position: absolute;
                                top: 0%;
                                left: 1.75rem;
                                transform: translateY(-50%);
                                background-color: rgba(v.$ps-white,1);
                                padding: 0.1rem 0.5rem;
                                font-size: 12px !important;
                                font-weight:600;
                                text-transform: uppercase;
                            }

                            input[type="text"],
                            input[type="email"]{
                                width:100%;
                                border: 0px;
                                border-bottom: 1px solid rgba(v.$ps-dark,0.35);

                                &.mce_inline_error{
                                    border-bottom: 1px solid red;
                                }

                                &:focus-visible{
                                    outline: none;
                                    border-bottom: 1px solid rgba(v.$ps-dark,1);

                                    &.mce_inline_error{
                                        border-bottom: 1px solid red;
                                    }
                                }
                            }

                            #mce-MMERGE6{
                                resize:vertical !important;
                                display: block !important;
                                overflow:hidden !important; 
                            }

                            select{
                                border: 0px;
                                border-bottom: 1px solid rgba(v.$ps-dark,0.35);
                                background-color: transparent;
                                width:100%;

                                &:focus-visible{
                                    outline: none;
                                }
                            }

                            div.mce_inline_error{
                                padding: 0rem 0.5rem;
                                margin: 0;
                                bottom: 0%;
                                left: 1.75rem;
                                position: absolute;
                                transform: translateY(55%);
                                font-size: 11px !important;
                                background-color: rgba(v.$ps-white,1);
                                font-weight: 600;
                                color: red !important;
                            }
                        }

                        .optionalParent{
                            grid-column: 4;

                            .foot{
                                display: flex;
                                align-items: flex-end;
                                justify-content: flex-end;
                            }
                        }
                        
                    }
                }
            }
        }
    }
}


@media screen and (max-width:1240px){
    .section--contact{
        .col{
            #mc_embed_shell{
                #mc_embed_signup{
                    form{
                        #mc_embed_signup_scroll{
                            grid-template-columns: 1fr 1fr;

                            #mce-responses,
                            .indicates-required{
                                grid-column: 1 / span 2;
                            }

                            .mc-field-group{
                                &.details{
                                    grid-column: 1 / span 2;
                                }
                            }

                            .optionalParent{
                                grid-column: 2;
                            }
                            
                        }
                    }
                }
            }
        }
    }
}


@media screen and (max-width:700px){
    .section--contact{
        .col{
            #mc_embed_shell{
                #mc_embed_signup{
                    form{
                        #mc_embed_signup_scroll{
                            grid-template-columns: 1fr;

                            #mce-responses,
                            .indicates-required{
                                grid-column: 1 / span 1;
                            }

                            .mc-field-group{
                                &.details{
                                    grid-column: 1 / span 1;
                                }
                            }

                            .optionalParent{
                                grid-column: 1;

                                .btn{
                                    width: 100%;
                                }
                            }
                            
                        }
                    }
                }
            }
        }
    }
}