@font-face {
    font-family: 'Red Hat Text';
    src: url('../../assets/fonts/RedHatText-Regular.woff2') format('woff2');
         font-weight: 400;
         font-display: swap;
         font-style: normal;
}

@font-face {
    font-family: 'Red Hat Text';
    src: url('../../assets/fonts/RedHatText-SemiBold.woff2') format('woff2');
         font-weight: 600;
         font-display: swap;
         font-style: normal;
}

@font-face {
    font-family: 'Red Hat Text';
    src: url('../../assets/fonts/RedHatText-Bold.woff2') format('woff2');
         font-weight: 700;
         font-display: swap;
         font-style: normal;
}

@font-face {
    font-family: 'Red Hat Display';
    src: url('../../assets/fonts/RedHatDisplay-Light.woff2') format('woff2');
         font-weight: 300;
         font-display: swap;
         font-style: normal;
}

@font-face {
    font-family: 'Red Hat Display';
    src: url('../../assets/fonts/RedHatDisplay-LightItalic.woff2') format('woff2');
         font-weight: 300;
         font-display: swap;
         font-style: italic;
}

@font-face {
    font-family: 'IBM Plex Serif';
    src: url('../../assets/fonts/IBMPlexSerif-LightItalic.woff2') format('woff2');
         font-weight: 300;
         font-display: swap;
         font-style: italic;
}

@font-face {
    font-family: 'Kalnia';
    src: url('../../assets/fonts/Kalnia-Regular.woff2') format('woff2');
         font-weight: 400;
         font-display: swap;
         font-style: normal;
}

@font-face {
    font-family: 'Kalnia';
    src: url('../../assets/fonts/Kalnia-Light.ttf') format('truetype');
         font-weight: 300;
         font-display: swap;
         font-style: normal;
}

@font-face {
    font-family: 'Kalnia';
    src: url('../../assets/fonts/Kalnia-ExtraLight.ttf') format('truetype');
         font-weight: 200;
         font-display: swap;
         font-style: normal;
}