/* Helpers */
/* ------------------------------------------------
    Breakpoints
-------------------------------------------------*/
/* ------------------------------------------------
    Typography
-------------------------------------------------*/
/* Fonts */
/* Font Sizes */
/* Font Weights */
/* SCSS Weight Map */
/* ------------------------------------------------
    Colours
-------------------------------------------------*/
/* SCSS Colours Maps */
/* ------------------------------------------------
    Other/Misc
-------------------------------------------------*/
:root {
  --border-radius: 30px;
  --header-height: 109px;
}

@media screen and (max-width: 550px) {
  :root {
    --border-radius: 1rem;
  }
}
@media screen and (max-width: 1000px) {
  :root {
    --header-height: 88px;
  }
}
/* Helpers */
/* Helpers */
/* ------------------------------------------------
    1. Functions used to build a custom clamp() function that can take in PX values and return REMS
    2. round() rounds any float to 2 decinmel places
    3. px-to-rem() converts a PX value to a REM value
-------------------------------------------------*/
/* Set default breakpoint px values */
/* Define round() function */
/* Define px-to-rem() function */
/* Define scssClamp() function */
/* Usage example: scssClamp(20px,40px) -- Breakpoint values are optional but custom values can be passed*/
/* ------------------------------------------------
    Typography
-------------------------------------------------*/
/* Typography Base */
/* ------------------------------------------------
    Layout
-------------------------------------------------*/
/* Helpers */
/* ------------------------------------------------
    Color Classes
-------------------------------------------------*/
.ps-dark-bg {
  background-color: #2F3531;
}

.ps-dark,
.ps-dark * {
  color: #2F3531;
}

:root {
  --ps-dark: #2F3531;
}

.ps-white-bg {
  background-color: #FFFFFF;
}

.ps-white,
.ps-white * {
  color: #FFFFFF;
}

:root {
  --ps-white: #FFFFFF;
}

/* ------------------------------------------------
    Weights
-------------------------------------------------*/
.weight-light,
.weight-300 {
  font-weight: 300;
}

.weight-regular,
.weight-400 {
  font-weight: 400;
}

.weight-semi-bold,
.weight-600 {
  font-weight: 600;
}

.weight-bold,
.weight-700 {
  font-weight: 700;
}

/* ------------------------------------------------
    Utility Classes
-------------------------------------------------*/
.content-width {
  width: 100%;
  max-width: 1240px;
}

.tablet-width {
  width: 100%;
  max-width: 980px;
}

.base-layout {
  margin-left: auto;
  margin-right: auto;
  padding: 0 1rem;
}

.has-no-margin {
  margin: 0 !important;
}

.has-no-gap {
  gap: 0 !important;
}

.is-margin-centered {
  margin-left: auto;
  margin-right: auto;
}

.is-text-centered {
  text-align: center;
}

.is-visually-hidden {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  word-wrap: normal !important;
}

.is-overflow-hidden {
  overflow: hidden;
}

/* ------------------------------------------------
    Grid Column Classes
-------------------------------------------------*/
.grid-cols {
  display: grid;
}
.grid-cols .col {
  display: flex;
  flex-direction: column;
  min-height: 0;
  min-width: 0;
}
.grid-cols .row {
  display: flex;
  flex-direction: row;
  min-height: 0;
  min-width: 0;
}

.grid-cols--1 {
  grid-template-columns: 1fr;
}

.grid-cols--5-7 {
  gap: 1em;
  grid-template-columns: 5fr 7fr;
}

.grid-cols--2-1 {
  gap: 1em;
  grid-template-columns: 2fr 1fr;
}

@media only screen and (max-width: 1000px) {
  .grid-cols--5-7,
  .grid-cols--2-1 {
    grid-template-columns: 1fr;
  }
}
/* Core */
@font-face {
  font-family: "Red Hat Text";
  src: url("../../assets/fonts/RedHatText-Regular.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Red Hat Text";
  src: url("../../assets/fonts/RedHatText-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Red Hat Text";
  src: url("../../assets/fonts/RedHatText-Bold.woff2") format("woff2");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Red Hat Display";
  src: url("../../assets/fonts/RedHatDisplay-Light.woff2") format("woff2");
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Red Hat Display";
  src: url("../../assets/fonts/RedHatDisplay-LightItalic.woff2") format("woff2");
  font-weight: 300;
  font-display: swap;
  font-style: italic;
}
@font-face {
  font-family: "IBM Plex Serif";
  src: url("../../assets/fonts/IBMPlexSerif-LightItalic.woff2") format("woff2");
  font-weight: 300;
  font-display: swap;
  font-style: italic;
}
@font-face {
  font-family: "Kalnia";
  src: url("../../assets/fonts/Kalnia-Regular.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Kalnia";
  src: url("../../assets/fonts/Kalnia-Light.ttf") format("truetype");
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Kalnia";
  src: url("../../assets/fonts/Kalnia-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-display: swap;
  font-style: normal;
}
/* Helpers */
/* Helpers */
/* ------------------------------------------------
    Heading Typography
-------------------------------------------------*/
/* Base Heading Stying */
h1, h2, h3, h4, h5, h6 {
  font-family: "Red Hat Text", sans-serif;
  font-style: normal;
  color: #2f3531;
  margin: 0;
  font-weight: 700;
}

h2 {
  letter-spacing: -0.05rem;
  line-height: 110%;
  text-transform: uppercase;
}

/* Generate/apply heading sizes, classes and CSS properties */
h1 {
  font-size: clamp(3.75rem, 7.1428571429vw + 1.9642857143rem, 7.5rem);
}

.huge-heading,
.huge-heading > h1 {
  font-size: clamp(3.75rem, 7.1428571429vw + 1.9642857143rem, 7.5rem);
}

:root {
  --huge-heading: clamp(3.75rem, 7.1428571429vw + 1.9642857143rem, 7.5rem);
}

h2.huge-heading,
.huge-heading > h2 {
  font-size: clamp(3.75rem, 7.1428571429vw + 1.9642857143rem, 7.5rem);
}

h3.huge-heading,
.huge-heading > h3 {
  font-size: clamp(3.75rem, 7.1428571429vw + 1.9642857143rem, 7.5rem);
}

h4.huge-heading,
.huge-heading > h4 {
  font-size: clamp(3.75rem, 7.1428571429vw + 1.9642857143rem, 7.5rem);
}

h5.huge-heading,
.huge-heading > h5 {
  font-size: clamp(3.75rem, 7.1428571429vw + 1.9642857143rem, 7.5rem);
}

h6.huge-heading,
.huge-heading > h6 {
  font-size: clamp(3.75rem, 7.1428571429vw + 1.9642857143rem, 7.5rem);
}

h2 {
  font-size: clamp(1.9375rem, 1.1904761905vw + 1.6398809524rem, 2.5625rem);
}

.x-large-heading,
.x-large-heading > h2 {
  font-size: clamp(1.9375rem, 1.1904761905vw + 1.6398809524rem, 2.5625rem);
}

:root {
  --x-large-heading: clamp(1.9375rem, 1.1904761905vw + 1.6398809524rem, 2.5625rem);
}

h1.x-large-heading,
.x-large-heading > h1 {
  font-size: clamp(1.9375rem, 1.1904761905vw + 1.6398809524rem, 2.5625rem);
}

h3.x-large-heading,
.x-large-heading > h3 {
  font-size: clamp(1.9375rem, 1.1904761905vw + 1.6398809524rem, 2.5625rem);
}

h4.x-large-heading,
.x-large-heading > h4 {
  font-size: clamp(1.9375rem, 1.1904761905vw + 1.6398809524rem, 2.5625rem);
}

h5.x-large-heading,
.x-large-heading > h5 {
  font-size: clamp(1.9375rem, 1.1904761905vw + 1.6398809524rem, 2.5625rem);
}

h6.x-large-heading,
.x-large-heading > h6 {
  font-size: clamp(1.9375rem, 1.1904761905vw + 1.6398809524rem, 2.5625rem);
}

/* ------------------------------------------------
    Body Typography
-------------------------------------------------*/
/* Base body styling */
p,
button,
ul,
ol,
li,
label,
input,
select,
small,
legend,
textarea,
details,
summary,
blockquote,
span {
  font-family: "Red Hat Text", sans-serif;
  line-height: 150%;
  color: #2f3531;
  font-weight: 400;
}

/* Generate/apply body sizes/classes and CSS properties */
.huge-body,
.huge-body * {
  font-size: clamp(1.5rem, 2.619047619vw + 0.8452380952rem, 2.875rem);
}

:root {
  --huge-body: clamp(1.5rem, 2.619047619vw + 0.8452380952rem, 2.875rem);
}

.x-large-body,
.x-large-body * {
  font-size: clamp(1.375rem, 0.5952380952vw + 1.2261904762rem, 1.6875rem);
}

:root {
  --x-large-body: clamp(1.375rem, 0.5952380952vw + 1.2261904762rem, 1.6875rem);
}

.normal-body,
.normal-body * {
  font-size: clamp(0.875rem, 0.2380952381vw + 0.8154761905rem, 1rem);
}

:root {
  --normal-body: clamp(0.875rem, 0.2380952381vw + 0.8154761905rem, 1rem);
}

.small-body, figure > video + figcaption,
figure > img + figcaption,
.small-body *,
figure > video + figcaption *,
figure > img + figcaption * {
  font-size: clamp(0.8125rem, 0.119047619vw + 0.7827380952rem, 0.875rem);
}

:root {
  --small-body: clamp(0.8125rem, 0.119047619vw + 0.7827380952rem, 0.875rem);
}

html {
  -webkit-text-size-adjust: 100%;
  font-family: "Red Hat Text", sans-serif;
  color: #2f3531;
  scrollbar-gutter: stable;
  font-size: 1em;
}

html.lenis {
  height: auto;
}

.lenis.lenis-smooth {
  scroll-behavior: auto !important;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.lenis.lenis-scrolling iframe {
  pointer-events: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  font-size: 1em;
}

*:focus-visible {
  outline: 2px solid #2F3531;
}

@media (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }
}
@media (prefers-reduced-motion) {
  html {
    scroll-behavior: auto;
  }
  * {
    transition: transform 0s linear, opacity 0s linear !important;
    animation-duration: 0s;
    animation-timing-function: linear;
  }
}
body {
  margin: 0;
}

main {
  display: block;
}

article {
  position: relative;
}

hr {
  box-sizing: content-box;
  height: 1px;
  overflow: visible;
  width: 100%;
  background-color: #2f3531;
  border: none;
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

a {
  background-color: transparent;
  cursor: pointer;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}

p {
  margin: 0.1em 0;
}

strong {
  font-weight: 600;
}

ul, ol {
  margin-block-start: 0;
  margin-block-end: 0.67rem;
  padding-inline-start: 1.35rem;
}

ul ol,
ul ul,
ol ul,
ol ol {
  margin-bottom: 0;
}

.inner-content ul li:not(:last-of-type),
.inner-content ol li:not(:last-of-type) {
  margin-bottom: 0.5rem;
}

code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.3em;
}

em {
  font-style: italic;
}

img {
  display: block;
  width: 100%;
  border-style: none;
  max-width: 100%;
  height: auto;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  padding-inline: 0px;
  padding-block: 0px;
}

::-moz-placeholder,
::placeholder,
::-webkit-input-placeholder {
  color: rgba(47, 53, 49, 0.75);
}

input:focus::placeholder,
input:focus::-moz-placeholder,
input:focus::-webkit-input-placeholder {
  opacity: 0.2;
}

button,
select {
  text-transform: none;
}

button,
[type=button],
[type=reset],
[type=submit] {
  appearance: button;
  -webkit-appearance: button;
  cursor: pointer;
  background: transparent;
  border: none;
  text-align: left;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type=checkbox],
[type=radio] {
  box-sizing: border-box;
  padding: 0;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  appearance: textfield;
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
  list-style: none;
}

summary {
  display: list-item;
  list-style: none;
}

template {
  display: none;
}

.js-enabled [hidden] {
  display: none;
}

figure {
  margin: 0;
}

figure > video + figcaption {
  margin-top: 5px;
}

fieldset {
  background: transparent;
  border: none;
  padding: 0;
  margin-bottom: 1.5rem;
}

blockquote {
  margin-inline: 0;
}

blockquote p:last-child {
  margin-bottom: 0;
}

/* Helpers */
.entry-content > article > .inner > *:not(.full-width):not(.tablet-width):not(header),
.entry-content > article > header > .inner {
  width: 100%;
  max-width: 1240px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 1rem;
}

.entry-content > article > .tablet-width {
  width: 100%;
  max-width: 980px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 1rem;
}

.entry-content > article > .inner > .section-container:not(:first-child) {
  margin-top: clamp(5rem, 10vw, 10rem);
}

/* Helpers */
#page {
  background-color: #0e1a12;
}
#page #content {
  background-color: #FFFFFF;
  padding-bottom: clamp(4rem, 10vw, 7rem);
  position: relative;
  z-index: 1;
  margin-bottom: var(--footer-height);
}
#page #content .hentry {
  overflow: hidden;
}
#page #content .hentry > .inner {
  padding-top: clamp(4rem, 10vw, 8rem);
  position: relative;
}
#page #content .hentry > .inner:before {
  content: "";
  width: 100%;
  position: absolute;
  height: 31px;
  background-color: #FFFFFF;
  left: 0;
  border-radius: var(--border-radius) var(--border-radius) 0px 0px;
  top: -30px;
}
#page #content:after {
  content: "";
  width: 100%;
  position: absolute;
  height: 31px;
  background-color: #FFFFFF;
  left: 0;
  border-radius: 0px 0px var(--border-radius) var(--border-radius);
  bottom: -30px;
}

.site-logo img {
  width: 100%;
  max-width: 160px;
  height: auto;
}

/* Partials */
/* Helpers */
header#masthead {
  color: #FFFFFF;
  padding-block: 2rem;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
}
header#masthead .content-width {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
header#masthead .base-layout {
  padding: 0 2rem;
}

/* Helpers */
.site-nav .site-menu {
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
  list-style: none;
  gap: 0.5rem;
}
.site-nav .site-menu .menu-item .menu-link {
  text-decoration: none;
  text-transform: uppercase;
  padding: 0.5rem 1rem;
  border-radius: 100vw;
}
.site-nav .site-menu .menu-item .menu-link:focus-visible {
  outline-color: #FFFFFF;
}
.site-nav .site-menu .menu-item .menu-link:focus-visible span {
  color: white;
}
.site-nav .site-menu .menu-item .menu-link span {
  color: rgba(255, 255, 255, 0.8);
  font-weight: 300;
}
.site-nav .site-menu .menu-item:last-child span {
  font-weight: 600;
  color: white;
}
.site-nav .mobile-menu-toggle {
  display: none;
}

@media screen and (max-width: 1000px) {
  .mobile-menu-toggle {
    display: flex !important;
    width: 45px;
    height: 45px;
    border-radius: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .mobile-menu-toggle[f11y-layer-open] {
    border: 1px solid #FFFFFF;
    flex-direction: column;
    gap: 5px;
  }
  .mobile-menu-toggle[f11y-layer-open]::before, .mobile-menu-toggle[f11y-layer-open]::after {
    content: "";
    width: 25px;
    height: 1px;
    border-radius: 3px;
    background-color: #FFFFFF;
    transform-origin: center;
  }
  .mobile-menu-toggle[f11y-layer-close] {
    background-color: #FFFFFF;
    position: absolute;
    top: -60px;
    left: 50%;
    transform: translate(-50%, 0px) rotate(45deg);
    z-index: 1;
  }
  .mobile-menu-toggle[f11y-layer-close]::before, .mobile-menu-toggle[f11y-layer-close]::after {
    content: "";
    position: absolute;
    width: 25px;
    height: 2px;
    border-radius: 3px;
    background-color: #2F3531;
    transform-origin: center;
  }
  .mobile-menu-toggle[f11y-layer-close]::before {
    transform: rotate(-90deg);
  }
  .mobile-menu-toggle:focus-visible {
    outline: 2px solid #FFFFFF;
    outline-offset: 4px;
  }
  .nav-modal {
    display: none;
    position: fixed;
    inset: 0;
    justify-content: center;
    align-items: flex-end;
    background: rgba(47, 53, 49, 0.1);
    backdrop-filter: blur(4px);
  }
  .nav-modal [role=dialog] {
    position: relative;
    max-width: calc(100% - 3rem);
    max-height: calc(90vh - 60px);
    background-color: #FFFFFF;
    border-radius: var(--border-radius) var(--border-radius) 0px 0px;
    height: 100%;
    width: 100%;
  }
  .nav-modal [role=dialog]:after {
    content: "";
    position: absolute;
    bottom: -100px;
    width: 100%;
    height: 100px;
    background-color: #FFFFFF;
  }
  .nav-modal [role=dialog] .site-menu {
    padding: 2rem 2rem;
    flex-direction: column;
    background-color: #FFFFFF;
    border-radius: var(--border-radius) var(--border-radius) 0px 0px;
    position: relative;
    z-index: 2;
    gap: 0;
  }
  .nav-modal [role=dialog] .site-menu .menu-item {
    padding: 0.3rem 0rem;
  }
  .nav-modal [role=dialog] .site-menu .menu-item:not(:last-child) {
    border-bottom: 1px solid rgba(47, 53, 49, 0.3);
  }
  .nav-modal [role=dialog] .site-menu .menu-item .menu-link {
    padding: 1rem 0.3rem;
    display: flex;
    border-radius: 0px;
  }
  .nav-modal [role=dialog] .site-menu .menu-item .menu-link:focus-visible {
    outline: 2px solid #2F3531;
  }
  .nav-modal [role=dialog] .site-menu .menu-item .menu-link span {
    color: #2F3531;
    font-family: "Red Hat Display";
    font-weight: 300;
    text-transform: capitalize;
    font-size: var(--x-large-heading);
    line-height: 130%;
  }
  .nav-modal.is-open {
    display: flex;
    z-index: 10;
  }
  .nav-modal.is-animating.is-opening [role=dialog] {
    animation: move-in 0.5s cubic-bezier(0.505, 0.215, 0.33, 1.19);
  }
  .nav-modal.is-animating.is-closing [role=dialog] {
    animation: move-out 0.5s cubic-bezier(0.505, 0.215, 0.33, 1.19);
  }
  .nav-modal.is-animating.is-opening {
    animation: nav-fade-in 0.5s cubic-bezier(0.4, 0, 0.6, 1);
  }
  .nav-modal.is-animating.is-closing {
    animation: nav-fade-out 0.5s cubic-bezier(0.4, 0, 0.6, 1);
  }
  .nav-modal.is-animating.is-opening .mobile-menu-toggle[f11y-layer-close] {
    animation: close-move-in 0.5s cubic-bezier(0.505, 0.215, 0.33, 1.19);
  }
  .nav-modal.is-animating.is-closing .mobile-menu-toggle[f11y-layer-close] {
    animation: close-move-out 0.5s cubic-bezier(0.505, 0.215, 0.33, 1.19);
  }
}
#content {
  transition: transform 0.4s ease;
}

.nav-open #content {
  transform-origin: top center;
  transform: scale(0.87) translateY(20vh);
  border-radius: var(--border-radius) var(--border-radius) 0px 0px;
  overflow: hidden;
}

@keyframes move-in {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0%);
  }
}
@keyframes move-out {
  from {
    transform: translateY(0%);
  }
  to {
    transform: translateY(100%);
  }
}
@keyframes close-move-in {
  from {
    transform: translate(-50%, 100px) rotate(45deg);
  }
  to {
    transform: translate(-50%, 0px) rotate(45deg);
  }
}
@keyframes close-move-out {
  from {
    transform: translate(-50%, 0px) rotate(45deg);
  }
  to {
    transform: translate(-50%, 100px) rotate(45deg);
  }
}
@keyframes nav-fade-in {
  from {
    backdrop-filter: blur(0px);
    background: rgba(47, 53, 49, 0);
  }
  to {
    backdrop-filter: blur(4px);
    background: rgba(47, 53, 49, 0.1);
  }
}
@keyframes nav-fade-out {
  from {
    backdrop-filter: blur(4px);
    background: rgba(47, 53, 49, 0.1);
  }
  to {
    backdrop-filter: blur(0px);
    background: rgba(47, 53, 49, 0);
  }
}
/* Components */
/* Helpers */
.btn {
  display: inline-flex !important;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 1.25em 4em;
  border-radius: 100vw;
  background-color: #04210D;
  color: #FFFFFF;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 600;
  position: relative;
  overflow: hidden;
  white-space: nowrap;
}
.btn:not(.outlined):after {
  content: "";
  position: absolute;
  width: 400px;
  height: 275px;
  background: radial-gradient(circle, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.7483193961) 27%, rgba(255, 255, 255, 0.8855742981) 38%, rgba(255, 255, 255, 0.9219888639) 50%, rgba(255, 255, 255, 0.8323530096) 63%, rgba(255, 255, 255, 0.6026611328) 73%, rgba(255, 255, 255, 0) 100%);
  right: 0%;
  bottom: -100%;
  opacity: 0.1;
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.6, 1);
}
.btn:focus-visible:after, .btn:hover:after {
  transform: scale(2);
}
.btn:focus-visible {
  outline: 2px solid #2F3531;
  outline-offset: 3px;
}
.btn.outlined {
  border: 1px solid #FFFFFF;
  background-color: transparent;
}
.btn.outlined:focus-visible {
  outline-width: 2px;
  outline-color: #FFFFFF;
}

@media screen and (max-width: 767px) {
  .btn {
    font-size: var(--small-body) !important;
  }
}
/* Helpers */
.video-modal {
  display: none;
  position: fixed;
  inset: 0;
  justify-content: center;
  align-items: center;
  padding: 3rem;
  background: rgba(47, 53, 49, 0.7);
  backdrop-filter: blur(4px);
}
.video-modal.is-open {
  display: flex;
  z-index: 3;
}
.video-modal [role=dialog] {
  width: 100%;
  max-width: 1208px;
  position: relative;
}
.video-modal [role=dialog] video {
  max-width: 100%;
  border-radius: var(--border-radius);
  overflow: hidden;
  box-shadow: 0px 4px 70.7px 0px rgba(0, 0, 0, 0.35);
  width: 100%;
  max-height: calc(100vh - 100px);
  background-color: black;
}
.video-modal [role=dialog] video:focus-visible {
  outline: 2px solid #FFFFFF;
  outline-offset: 4px;
}
.video-modal [role=dialog] .x-close {
  background-color: #04210D;
  width: 60px;
  height: 60px;
  position: absolute;
  right: -25px;
  top: -25px;
  border-radius: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(45deg);
}
.video-modal [role=dialog] .x-close::before, .video-modal [role=dialog] .x-close::after {
  content: "";
  position: absolute;
  width: 25px;
  height: 2px;
  border-radius: 3px;
  background-color: #FFFFFF;
  transform-origin: center;
}
.video-modal [role=dialog] .x-close::before {
  transform: rotate(-90deg);
}
.video-modal [role=dialog] .x-close:focus-visible {
  outline: 2px solid #FFFFFF;
  outline-offset: -8px;
}
.video-modal.is-animating.is-opening [role=dialog] {
  animation: scale-in 0.4s cubic-bezier(0.4, 0, 0.6, 1);
}
.video-modal.is-animating.is-closing [role=dialog] {
  animation: scale-out 0.4s cubic-bezier(0.4, 0, 0.6, 1);
}
.video-modal.is-animating.is-opening {
  animation: fade-in 0.4s cubic-bezier(0.4, 0, 0.6, 1);
}
.video-modal.is-animating.is-closing {
  animation: fade-out 0.4s cubic-bezier(0.4, 0, 0.6, 1);
}

@media screen and (max-width: 767px) {
  .video-modal {
    padding: 1rem;
  }
  .video-modal .x-close {
    right: auto !important;
    left: 50% !important;
    transform: rotate(45deg) translateX(-50%) !important;
    top: -60px !important;
  }
}
@keyframes scale-in {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes scale-out {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.9);
  }
}
@keyframes fade-in {
  from {
    backdrop-filter: blur(0px);
    background: rgba(47, 53, 49, 0);
  }
  to {
    backdrop-filter: blur(4px);
    background: rgba(47, 53, 49, 0.7);
  }
}
@keyframes fade-out {
  from {
    backdrop-filter: blur(4px);
    background: rgba(47, 53, 49, 0.7);
  }
  to {
    backdrop-filter: blur(0px);
    background: rgba(47, 53, 49, 0);
  }
}
/* Sections */
/* Helpers */
#section--hero {
  position: relative;
  padding-top: var(--header-height);
}
#section--hero .inner {
  position: relative;
  z-index: 2;
}
#section--hero .inner .grid-cols--2-1 {
  padding-top: clamp(2.5rem, 10vw, 5rem);
}
#section--hero .inner .grid-cols--2-1 h1 {
  line-height: 100%;
  text-transform: uppercase;
}
#section--hero .inner .grid-cols--2-1 h1 span {
  line-height: 100%;
  display: block;
}
#section--hero .inner .grid-cols--2-1 h1 span.heading--outlined {
  color: transparent;
  font-weight: 700;
  -webkit-text-stroke: 1px #FFFFFF;
  -webkit-text-stroke-width: 1;
  -webkit-text-stroke-color: #FFFFFF;
  letter-spacing: -0.15rem;
}
#section--hero .inner .grid-cols--2-1 h1 span.heading--serif {
  font-family: "Kalnia";
  color: #FFFFFF;
  letter-spacing: -0.375rem;
  font-weight: 400;
  padding-left: 0.7em;
}
#section--hero .inner .grid-cols--2-1 .cta {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  justify-self: flex-end;
  align-items: flex-end;
  margin-top: auto;
  border-top: 1px solid rgba(255, 255, 255, 0.35);
  padding-top: 1rem;
  padding-bottom: 1rem;
}
#section--hero .inner .grid-cols--2-1 .cta * {
  text-align: right;
  text-transform: uppercase;
  color: #FFFFFF;
}
#section--hero .inner--full {
  padding-bottom: 2rem;
}
#section--hero .gallery-swiper {
  padding-top: clamp(3.5rem, 12vw, 7rem);
  padding-bottom: clamp(4rem, 10vw, 8rem);
}
#section--hero .gallery-swiper:focus-visible {
  outline: 2px solid #FFFFFF;
  outline-offset: -5px;
  border-radius: var(--border-radius);
}
#section--hero .gallery-swiper .swiper-wrapper .swiper-slide figure img {
  box-shadow: 0px 4px 70.7px 0px rgba(0, 0, 0, 0.35);
}
#section--hero .section__background {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
}
#section--hero .section__background img {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (max-width: 1000px) {
  #section--hero .grid-cols .col:last-child {
    margin-left: calc(var(--huge-heading) * 0.7);
  }
  #section--hero .grid-cols .col:last-child .cta {
    align-items: flex-start;
  }
  #section--hero .grid-cols .col:last-child .cta * {
    text-align: left;
  }
}
@media screen and (max-width: 400px) {
  #section--hero .heading--serif {
    padding-left: 0 !important;
  }
  #section--hero .grid-cols .col:last-child {
    margin-left: 0;
  }
  #section--hero .grid-cols .col .btn {
    width: 100%;
  }
}
/* Helpers */
.section--service {
  position: relative;
}
.section--service .col:first-child {
  padding-right: 100px;
  gap: 3rem;
}
.section--service .col figure blockquote {
  margin: 0;
  border-left: 10px solid rgba(47, 53, 49, 0.08);
  padding-left: 2rem;
}
.section--service .col figure blockquote p {
  font-family: "IBM Plex Serif", serif;
  font-size: var(--huge-body);
  line-height: 150%;
  letter-spacing: -0.02875rem;
  font-weight: 300;
  margin: 0;
}
.section--service .col figure figcaption {
  text-transform: uppercase;
  padding-top: 1rem;
  text-align: right;
  padding-right: 30px;
}
.section--service .col .cta {
  display: flex;
  flex-direction: column;
  gap: 1.35rem;
  justify-self: flex-end;
  align-items: flex-start;
  margin-top: auto;
  border-top: 1px solid rgba(47, 53, 49, 0.35);
  padding-top: 0.75rem;
}
.section--service .col .cta * {
  text-transform: uppercase;
}
.section--service .service__poster {
  position: relative;
  border-radius: var(--border-radius);
  overflow: hidden;
  margin-top: clamp(2rem, 7vw, 4rem);
}
.section--service .service__poster figcaption {
  position: absolute;
  top: 2rem;
  left: 2rem;
  text-transform: uppercase;
  color: #FFFFFF;
}
.section--service .service__poster button {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.section--service .service__poster button:focus-visible {
  outline: 2px solid #FFFFFF;
  outline-offset: -8px;
}
.section--service.section--burns .flourish, .section--service.section--weddings .flourish {
  max-width: 275px;
  height: auto;
  position: absolute;
  right: -3%;
  top: -8%;
  z-index: -1;
}
.section--service.section--funerals .flourish {
  max-width: 275px;
  height: auto;
  position: absolute;
  right: -5%;
  top: 8%;
  z-index: -1;
}

@media screen and (max-width: 1000px) {
  .section--service .grid-cols--5-7 {
    gap: 2.5rem;
  }
  .section--service .col:first-child {
    padding-right: 0 !important;
    gap: 1.5rem !important;
  }
  .section--service .col figure figcaption {
    text-align: left !important;
    padding-left: calc(10px + 2rem);
    padding-right: calc(10px + 2rem);
    padding-top: 1.5rem;
    font-size: var(--small-body);
  }
  .section--service.section--burns .flourish, .section--service.section--weddings .flourish {
    top: -2% !important;
  }
  .section--service.section--funerals .flourish {
    top: 6% !important;
  }
}
@media screen and (max-width: 550px) {
  .section--service .cta .btn {
    width: 100%;
  }
  .section--service .service__poster figcaption {
    left: 1.15rem;
    top: 1rem;
    font-size: var(--small-body);
  }
  .section--service .service__poster .btn {
    top: auto;
    bottom: 1rem;
    transform: translate(-50%, 0%);
  }
}
/* Helpers */
.section--service--mini .col {
  gap: 1rem !important;
  padding: 0 !important;
}
.section--service--mini .col h2 {
  padding: 0 !important;
}
.section--service--mini .col p {
  font-family: "Red Hat Display";
  font-size: var(--huge-body);
  font-weight: 300;
  font-style: italic;
  line-height: 140%;
  letter-spacing: -0.03rem;
}
.section--service--mini .service__poster {
  margin-top: clamp(1.5rem, 6vw, 3rem);
}

/* Helpers */
.section--intro {
  position: relative;
}
.section--intro .col {
  gap: 2rem;
}
.section--intro .col > p {
  font-family: "Red Hat Display";
  font-size: var(--huge-body);
  font-weight: 300;
}
.section--intro .col .video-autoplay {
  max-width: 900px;
  border-radius: var(--border-radius);
  overflow: hidden;
  position: relative;
}
.section--intro .col .video-autoplay video {
  width: 100%;
  display: block;
}
.section--intro .col .video-autoplay button {
  position: absolute;
  left: 50%;
  bottom: 0%;
  padding: 1.25em 1.25em !important;
  transform: translate(-50%, -50%) scale(0.85);
  opacity: 0.65;
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.6, 1), opacity 0.3s cubic-bezier(0.4, 0, 0.6, 1);
}
.section--intro .col .video-autoplay button:focus-visible {
  outline: 2px solid #FFFFFF;
  outline-offset: -8px;
}
.section--intro .col .video-autoplay button.is-muted .volume {
  display: none;
}
.section--intro .col .video-autoplay button:not(.is-muted) .muted {
  display: none;
}
.section--intro .col .video-autoplay:focus-within button, .section--intro .col .video-autoplay:hover button {
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
}
.section--intro .col .intro__poster {
  position: relative;
  border-radius: var(--border-radius);
  overflow: hidden;
  max-width: 900px;
}
.section--intro .col .intro__poster button {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.section--intro .col .intro__poster button:focus-visible {
  outline: 2px solid #FFFFFF;
  outline-offset: -8px;
}
.section--intro .col .cta {
  align-self: flex-start;
  justify-self: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 1.35rem;
  justify-self: flex-end;
  align-items: flex-start;
  margin-top: 2rem;
  border-top: 1px solid rgba(47, 53, 49, 0.35);
  padding-top: 0.75rem;
  padding-bottom: 3rem;
}
.section--intro .col .cta * {
  text-transform: uppercase;
}
.section--intro .flourish {
  position: absolute;
  right: -30%;
  bottom: 5%;
  z-index: -1;
}

@media screen and (max-width: 767px) {
  .section--intro .flourish {
    max-width: 75vw;
    right: -10%;
  }
}
/* Helpers */
.gallery-swiper {
  padding-left: calc((100vw - 1200px) / 2);
}
.gallery-swiper:focus-visible {
  outline: 2px solid #2F3531;
  outline-offset: -5px;
  border-radius: var(--border-radius);
}
.gallery-swiper .swiper-wrapper {
  align-items: center;
}
.gallery-swiper .swiper-wrapper * {
  user-select: none;
}
.gallery-swiper .swiper-wrapper .swiper-slide {
  width: auto !important;
  padding-right: clamp(1rem, 5vw, 3.25rem);
}
.gallery-swiper .swiper-wrapper .swiper-slide figure img {
  max-width: 450px;
  max-height: 450px;
  width: 100%;
  height: auto;
}
.gallery-swiper .swiper-wrapper .swiper-slide figure img.small {
  max-height: 350px;
}
.gallery-swiper .swiper-wrapper .swiper-slide:last-child {
  padding-right: calc((100vw - 1240px) / 2);
}

@media screen and (max-width: 1260px) {
  .gallery-swiper .swiper-wrapper .swiper-slide:last-child {
    padding-right: 1.5rem !important;
  }
  .gallery-swiper .swiper-wrapper .swiper-slide:first-child {
    padding-left: 1.5rem !important;
  }
}
@media screen and (max-width: 1000px) {
  .gallery-swiper .swiper-wrapper .swiper-slide figure img {
    max-width: 350px;
    max-height: 350px;
  }
  .gallery-swiper .swiper-wrapper .swiper-slide figure img.small {
    max-height: 250px;
  }
}
@media screen and (max-width: 400px) {
  .gallery-swiper .swiper-wrapper .swiper-slide figure img {
    max-width: 70vw;
    max-height: 250px;
  }
  .gallery-swiper .swiper-wrapper .swiper-slide figure img.small {
    max-height: 200px;
  }
}
/* Helpers */
.section--testimonial .inner {
  position: relative;
  border-radius: var(--border-radius);
  overflow: hidden;
}
.section--testimonial .inner .col {
  position: relative;
  z-index: 2;
  max-width: 767px;
  padding: clamp(1.35rem, 7vw, 4rem);
  padding-bottom: 250px;
}
.section--testimonial .inner .col > img {
  max-width: 120px;
  height: auto;
}
.section--testimonial .inner .col blockquote {
  padding-top: 0.5rem;
}
.section--testimonial .inner .col blockquote p {
  font-family: "Red Hat Display";
  font-weight: 300;
  font-style: italic;
  font-size: var(--x-large-body);
  color: #FFFFFF;
}
.section--testimonial .inner .col blockquote footer {
  display: flex;
  flex-direction: column;
  text-transform: uppercase;
  color: #FFFFFF;
  padding-top: 1.5rem;
}
.section--testimonial .inner .col blockquote footer cite {
  display: block;
  font-style: normal;
}
.section--testimonial .inner .col .flourish {
  position: absolute;
  margin-top: 2rem;
  margin-right: 2rem;
  top: calc(100% - 200px);
}
.section--testimonial .inner .col .flourish img {
  opacity: 0;
  transform: translateY(20%);
  transition: opacity 0.6s cubic-bezier(0.26, 0.28, 0.41, 0.7) 0.1s, transform 0.7s cubic-bezier(0.26, 0.28, 0.41, 0.7);
}
.section--testimonial .inner .col .flourish.has-transformed img {
  opacity: 1;
  transform: translateY(0%);
}
.section--testimonial .inner > img {
  position: absolute;
  height: 100%;
  width: 100%;
  inset: 0;
  z-index: 1;
  object-fit: cover;
}

@media screen and (max-width: 550px) {
  .section--testimonial .inner .col > img {
    max-width: 80px;
    margin-top: 1rem;
  }
  .section--testimonial footer {
    font-size: var(--small-body);
  }
}
/* Helpers */
.section--contact {
  position: relative;
}
.section--contact .col:first-child {
  max-width: 403px;
}
.section--contact .col h2 {
  padding-bottom: 3rem;
}
.section--contact .col .cta {
  display: flex;
  flex-direction: column;
  gap: 1.35rem;
  justify-self: flex-end;
  align-items: flex-start;
  margin-top: auto;
  border-top: 1px solid rgba(47, 53, 49, 0.35);
  padding-top: 0.75rem;
}
.section--contact .col .cta p:first-of-type {
  text-transform: uppercase;
}
.section--contact .col .cta a {
  font-weight: 600;
  color: #2F3531;
  text-decoration: none;
  border-bottom: 2px solid #2F3531;
}
.section--contact .col .cta a:focus-visible {
  border: none;
  outline: 2px solid #2F3531;
  border-radius: 50px;
  outline-offset: 4px;
}
.section--contact .col #mc_embed_shell #mc_embed_signup form #mc_embed_signup_scroll {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: repeat(3, minmax(55px, auto));
  grid-gap: 1.5rem;
}
.section--contact .col #mc_embed_shell #mc_embed_signup form #mc_embed_signup_scroll * {
  font-family: "Red Hat Text", sans-serif !important;
}
.section--contact .col #mc_embed_shell #mc_embed_signup form #mc_embed_signup_scroll #mce-responses,
.section--contact .col #mc_embed_shell #mc_embed_signup form #mc_embed_signup_scroll .indicates-required {
  width: 100%;
  grid-column: 1/span 4;
}
.section--contact .col #mc_embed_shell #mc_embed_signup form #mc_embed_signup_scroll .indicates-required {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  font-size: 14px;
  font-weight: 600;
  padding-right: 0.75rem;
}
.section--contact .col #mc_embed_shell #mc_embed_signup form #mc_embed_signup_scroll .mc-field-group {
  border: 1px solid #2f3531;
  border-radius: 100vw;
  padding: 1rem 1.75rem 0.75rem;
  position: relative;
  flex-grow: 1;
  width: 100%;
}
.section--contact .col #mc_embed_shell #mc_embed_signup form #mc_embed_signup_scroll .mc-field-group.details {
  grid-column: 1/span 4;
}
.section--contact .col #mc_embed_shell #mc_embed_signup form #mc_embed_signup_scroll .mc-field-group:focus-within {
  outline: 2px solid #2f3531;
  outline-offset: 2px !important;
}
.section--contact .col #mc_embed_shell #mc_embed_signup form #mc_embed_signup_scroll .mc-field-group label {
  position: absolute;
  top: 0%;
  left: 1.75rem;
  transform: translateY(-50%);
  background-color: white;
  padding: 0.1rem 0.5rem;
  font-size: 12px !important;
  font-weight: 600;
  text-transform: uppercase;
}
.section--contact .col #mc_embed_shell #mc_embed_signup form #mc_embed_signup_scroll .mc-field-group input[type=text],
.section--contact .col #mc_embed_shell #mc_embed_signup form #mc_embed_signup_scroll .mc-field-group input[type=email] {
  width: 100%;
  border: 0px;
  border-bottom: 1px solid rgba(47, 53, 49, 0.35);
}
.section--contact .col #mc_embed_shell #mc_embed_signup form #mc_embed_signup_scroll .mc-field-group input[type=text].mce_inline_error,
.section--contact .col #mc_embed_shell #mc_embed_signup form #mc_embed_signup_scroll .mc-field-group input[type=email].mce_inline_error {
  border-bottom: 1px solid red;
}
.section--contact .col #mc_embed_shell #mc_embed_signup form #mc_embed_signup_scroll .mc-field-group input[type=text]:focus-visible,
.section--contact .col #mc_embed_shell #mc_embed_signup form #mc_embed_signup_scroll .mc-field-group input[type=email]:focus-visible {
  outline: none;
  border-bottom: 1px solid #2f3531;
}
.section--contact .col #mc_embed_shell #mc_embed_signup form #mc_embed_signup_scroll .mc-field-group input[type=text]:focus-visible.mce_inline_error,
.section--contact .col #mc_embed_shell #mc_embed_signup form #mc_embed_signup_scroll .mc-field-group input[type=email]:focus-visible.mce_inline_error {
  border-bottom: 1px solid red;
}
.section--contact .col #mc_embed_shell #mc_embed_signup form #mc_embed_signup_scroll .mc-field-group #mce-MMERGE6 {
  resize: vertical !important;
  display: block !important;
  overflow: hidden !important;
}
.section--contact .col #mc_embed_shell #mc_embed_signup form #mc_embed_signup_scroll .mc-field-group select {
  border: 0px;
  border-bottom: 1px solid rgba(47, 53, 49, 0.35);
  background-color: transparent;
  width: 100%;
}
.section--contact .col #mc_embed_shell #mc_embed_signup form #mc_embed_signup_scroll .mc-field-group select:focus-visible {
  outline: none;
}
.section--contact .col #mc_embed_shell #mc_embed_signup form #mc_embed_signup_scroll .mc-field-group div.mce_inline_error {
  padding: 0rem 0.5rem;
  margin: 0;
  bottom: 0%;
  left: 1.75rem;
  position: absolute;
  transform: translateY(55%);
  font-size: 11px !important;
  background-color: white;
  font-weight: 600;
  color: red !important;
}
.section--contact .col #mc_embed_shell #mc_embed_signup form #mc_embed_signup_scroll .optionalParent {
  grid-column: 4;
}
.section--contact .col #mc_embed_shell #mc_embed_signup form #mc_embed_signup_scroll .optionalParent .foot {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

@media screen and (max-width: 1240px) {
  .section--contact .col #mc_embed_shell #mc_embed_signup form #mc_embed_signup_scroll {
    grid-template-columns: 1fr 1fr;
  }
  .section--contact .col #mc_embed_shell #mc_embed_signup form #mc_embed_signup_scroll #mce-responses,
  .section--contact .col #mc_embed_shell #mc_embed_signup form #mc_embed_signup_scroll .indicates-required {
    grid-column: 1/span 2;
  }
  .section--contact .col #mc_embed_shell #mc_embed_signup form #mc_embed_signup_scroll .mc-field-group.details {
    grid-column: 1/span 2;
  }
  .section--contact .col #mc_embed_shell #mc_embed_signup form #mc_embed_signup_scroll .optionalParent {
    grid-column: 2;
  }
}
@media screen and (max-width: 700px) {
  .section--contact .col #mc_embed_shell #mc_embed_signup form #mc_embed_signup_scroll {
    grid-template-columns: 1fr;
  }
  .section--contact .col #mc_embed_shell #mc_embed_signup form #mc_embed_signup_scroll #mce-responses,
  .section--contact .col #mc_embed_shell #mc_embed_signup form #mc_embed_signup_scroll .indicates-required {
    grid-column: 1/span 1;
  }
  .section--contact .col #mc_embed_shell #mc_embed_signup form #mc_embed_signup_scroll .mc-field-group.details {
    grid-column: 1/span 1;
  }
  .section--contact .col #mc_embed_shell #mc_embed_signup form #mc_embed_signup_scroll .optionalParent {
    grid-column: 1;
  }
  .section--contact .col #mc_embed_shell #mc_embed_signup form #mc_embed_signup_scroll .optionalParent .btn {
    width: 100%;
  }
}
/* Helpers */
#colophon {
  position: fixed;
  padding-top: clamp(4rem, 7vw, 6rem);
  padding-bottom: clamp(2rem, 7vw, 3rem);
  bottom: 0px;
  z-index: 0;
  width: 100%;
}
#colophon a {
  text-decoration: none;
  font-weight: 600;
  border-radius: 20px;
}
#colophon a:focus-visible {
  outline: 2px solid #FFFFFF;
  outline-offset: 10px;
}
#colophon .base-layout {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: clamp(1.5rem, 7vw, 3rem);
}
#colophon .base-layout .row {
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1.5rem;
}
#colophon .base-layout .row .copyright {
  display: flex;
  gap: 1rem;
}
#colophon .base-layout .row .copyright .seperator {
  width: 1px;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
}
#colophon .base-layout .row .credits a {
  display: flex;
  gap: 0.5rem;
}
#colophon .base-layout .row .credits a span {
  white-space: nowrap;
  font-weight: 600;
}
#colophon .section__background {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
}
#colophon .section__background img {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.nav-open #colophon {
  display: none;
}