@use 'helpers/variables' as v;
@use 'typography' as t;
@use 'helpers/mixins' as m;
@use 'helpers/classes' as c;
@use 'helpers/functions' as f;


.entry-content > article > .inner > *:not(.full-width):not(.tablet-width):not(header),
.entry-content > article > header > .inner{
    @include m.content-width;
    @include m.base-layout;
}

.entry-content > article > .tablet-width {
    @include m.tablet-width;
    @include m.base-layout;
}

.entry-content > article > .inner > .section-container:not(:first-child) {
    margin-top: clamp(5rem,10vw,10rem);
}