/* ------------------------------------------------
    Breakpoints
-------------------------------------------------*/
$mobile-breakpoint: 400;
$desktop-breakpoint: 1240;
$tablet-size: calc($desktop-breakpoint - $mobile-breakpoint);


/* ------------------------------------------------
    Typography
-------------------------------------------------*/
/* Fonts */
$heading-font: 'Red Hat Text' , sans-serif;
$body-font: 'Red Hat Text' , sans-serif;
$pre-font: 'IBM Plex Serif' , serif;

/* Font Sizes */
$heading-sizes: (
    "h1" : (
        "slug" : "huge",
        "mobile" : 60px,
        "desktop" : 120px,
    ),
    "h2" : (
        "slug" : "x-large",
        "mobile" : 31px,
        "desktop" : 41px
    )
);

$body-sizes: (
    "huge" : (
        "mobile" : 24px,
        "desktop" : 46px,
    ),
    "x-large" : (
        "mobile" : 22px,
        "desktop" : 27px
    ),
    "normal" : (
        "mobile" : 14px,
        "desktop" : 16px
    ),
    "small" : (
        "mobile" : 13px,
        "desktop" : 14px
    )
);

/* Font Weights */
$weight-light: 300;
$weight-regular: 400;
$weight-semi-bold : 600; 
$weight-bold: 700;

$default-weight: $weight-regular;
$strong-weight: $weight-semi-bold;

/* SCSS Weight Map */
$font-weights: (
    "weight-light" : $weight-light,
    "weight-regular" : $weight-regular,
    "weight-semi-bold" : $weight-semi-bold,
    "weight-bold" : $weight-bold
);


/* ------------------------------------------------
    Colours
-------------------------------------------------*/
$ps-dark: #2F3531;
$ps-white: #FFFFFF;

/* SCSS Colours Maps */
$colours: (
    ps-dark: $ps-dark,
    ps-white: $ps-white
);


/* ------------------------------------------------
    Other/Misc
-------------------------------------------------*/
$timing-function: cubic-bezier(.4,0,.6,1);

:root{
    --border-radius: 30px;
    --header-height: 109px;
}

@media screen and (max-width:550px){
    :root{
        --border-radius: 1rem;
    }
}

@media screen and (max-width:1000px){
    :root{
        --header-height: 88px;
    }
}