@use "helpers/variables" as v;
@use "core/typography" as t;
@use "helpers/classes" as c;
@use "helpers/mixins" as m;

header#masthead{
    color: v.$ps-white;
    padding-block: 2rem;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;

    .content-width{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    .base-layout{
        padding: 0 2rem;
    }
}